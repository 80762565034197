<template>
    <CCard>
        <CCardHeader>
            <strong>&nbsp; &nbsp; General Settings </strong> <span
                style="color: red; padding-bottom: -30px; font-size: 10px"> (db) </span> Edit
        </CCardHeader>
        <CCardBody>
            <CForm>
                <CInput
                        description="Accesspoint MAC address"
                        label="Device MAC"
                        horizontal
                        id="apMac"
                        type="text"
                        :value="ap.apMac"
                        disabled
                />

                <CInput
                        description="Accesspoint Name"
                        label="Device Name"
                        horizontal
                        id="deviceName"
                        type="text"
                        :value="ap.deviceName"
                />

                <CInput
                        description="Accesspoint Description"
                        label="Device Description"
                        horizontal
                        id="description"
                        type="text"
                        :value="ap.description"
                />

                <CInput
                        description="Accesspoint Zone Name"
                        label="Device Zone Name"
                        horizontal
                        id="zoneName"
                        type="text"
                        :value="ap.zoneName"
                        disabled
                />

                <CInput
                        description="Accesspoint Zone Id"
                        label="Device Zone Id"
                        horizontal
                        id="zoneId"
                        type="text"
                        :value="ap.zoneId"
                        disabled
                />

                <CInput
                        description="Accesspoint Status"
                        label="Device Status"
                        horizontal
                        id="status"
                        type="text"
                        :value="ap.status"
                        disabled
                />
                <CInput
                        description="Accesspoint IP"
                        label="Device IP"
                        horizontal
                        id="ip"
                        type="text"
                        :value="ap.ip"
                />

                <CInput
                        description="Accesspoint Model"
                        label="Device Model"
                        horizontal
                        id="model"
                        type="text"
                        :value="ap.model"
                        disabled
                />
                <CInput
                        description="Accesspoint Serial"
                        label="Device Serial"
                        horizontal
                        id="serial"
                        type="text"
                        :value="ap.serial"
                        disabled
                />

            </CForm>
        </CCardBody>
        <CCardHeader>
            <strong>&nbsp; &nbsp; Wlan Group Settings </strong> <span
                style="color: red; padding-bottom: -30px; font-size: 10px"> (db) </span> Edit
        </CCardHeader>
        <CCardBody>
            <CForm>
                <CInput
                        description="WlanGroup 2.4Ghz Name"
                        label="Device WG24"
                        horizontal
                        id="wlanGroup24Name"
                        type="text"
                        :value="ap.wlanGroup24Name"
                        disabled
                />

                <CInput
                        description="WlanGroup 2.4Ghz Id"
                        label="Device WG24 Id"
                        horizontal
                        id="wlanGroup24Id"
                        type="text"
                        :value="ap.wlanGroup24Id"
                        disabled
                />

                <CInput
                        description="WlanGroup 5Ghz Name"
                        label="Device WG50"
                        horizontal
                        id="wlanGroup50Name"
                        type="text"
                        :value="ap.wlanGroup50Name"
                        disabled
                />

                <CInput
                        description="WlanGroup 5Ghz Id"
                        label="Device WG50 Id"
                        horizontal
                        id="wlanGroup50Id"
                        type="text"
                        :value="ap.wlanGroup50Id"
                        disabled
                />
            </CForm>
        </CCardBody>

        <CCardHeader>
            <strong>&nbsp; &nbsp; Channel Info </strong> <span
                style="color: red; padding-bottom: -30px; font-size: 10px"> (db) </span> Edit
        </CCardHeader>
        <CCardBody>
            <CForm>
                <CInput
                        description="Channel 2.4G"
                        label="Channel 2.4GHz"
                        horizontal
                        id="channel24G"
                        type="text"
                        :value="ap.channel24G"
                        disabled
                />

                <CInput
                        description="Channel 5G"
                        label="Channel 5.0GHz"
                        horizontal
                        id="channel5G"
                        type="text"
                        :value="ap.channel5G"
                        disabled
                />

            </CForm>
        </CCardBody>

        <CCardHeader>
            <strong>&nbsp; &nbsp; Hosted WLANs Info </strong> <span
                style="color: red; padding-bottom: -30px; font-size: 10px"> (db) </span> Edit
            <div v-if="hostedNetworks" class="lds-ripple">
                <div></div>
                <div></div>
            </div>
            <CButton v-if="!hostedNetworks" type="submit" size="sm" @click="apMembers()" color="info">
                🔄 with controller
            </CButton>
        </CCardHeader>
        <CCard>
            <CCardHeader>
                <CIcon name="cil-justify-center"/>
                <strong> WG24 WLAN Members </strong><small></small>
            </CCardHeader>
            <CCardBody>
                <CListGroup>
                    <template v-for="wg24 in ap_members.members_24">
                    <CListGroupItem  href="#"
                                    class="flex-column align-items-start">
                        <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">WLAN Name: {{wg24.name}}</h5>
                        </div>
                        <p class="mb-1">
                            WLAN Id : {{wg24.id}} <br>
                            Access Wlan: {{wg24.accessVlan}} <br>
                            Vlan Pooling: {{wg24.vlanPooling}} <br>
                        </p>
                    </CListGroupItem>
                    </template>
                </CListGroup>

            </CCardBody>
        </CCard>

        <CCard>
            <CCardHeader>
                <CIcon name="cil-justify-center"/>
                <strong> WG50 WLAN Members </strong><small></small>
            </CCardHeader>
            <CCardBody>
                <CListGroup>
                    <template v-for="wg50 in ap_members.members_50">
                        <CListGroupItem  href="#"
                                         class="flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">WLAN Name: {{wg50.name}}</h5>
                            </div>
                            <p class="mb-1">
                                WLAN Id : {{wg50.id}} <br>
                                Access Wlan: {{wg50.accessVlan}} <br>
                                Vlan Pooling: {{wg50.vlanPooling}} <br>
                            </p>
                        </CListGroupItem>
                    </template>
                </CListGroup>

            </CCardBody>
        </CCard>

    </CCard>
</template>
<script>
    import axios from 'axios';

    export default {
        name: 'AccessPoint',
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.usersOpened = from.fullPath.includes('accesspoints')
            })
        },
        data() {
            return {

                usersOpened: null,
                rctl_id: null,
                ap: null,
                ctrlSyncFlagAP: false,
                hostedNetworks: false,
                ap_members: {'members_24': [],
                'members_50': []},
                vxur: false,
                index: -14000,
            }
        },
        methods: {
            goBack() {
                this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/accesspoints'})
            },
            capitalizeFirstLetter(st) {
                var str_split = st.split('_')
                var new_array = []

                for (var ch = 0; ch < str_split.length; ch++) {
                    var newstr = str_split[ch].charAt(0).toUpperCase() + str_split[ch].slice(1);
                    new_array.push(newstr)
                }

                return new_array.join(' ')
            },
            wlanQuery() {

                const mac_address = this.$route.params.mac_address
                this.rctl_id = parseInt(this.$route.params.rctl_id)
                this.ctrlSyncFlagAP = true;
                axios.get(this.$baseURL + '/ruckus/ap/details?ap_mac=' + mac_address + '&rctl_id=' + this.rctl_id, this.ap)
                    .then((res) => {
                        this.ap = res.data;
                        this.$toast.success("Successfully synced AP with controller");
                        this.ctrlSyncFlagAP = false;
                        this.apMembers();
                    }).catch((error) => {
                    this.ctrlSyncFlagAP = false;
                    this.$toast.error("Failed to sync AP due to " + error);
                    console.log(error);
                })
            },
            getById(id) {
                return document.getElementById(id)._value
            },
            apMembers() {
                this.hostedNetworks = true;
                axios.get(`${this.$baseURL}/ruckus/ap/members?zone_id=${this.ap.zoneId}&wg_24_id=${this.ap.wlanGroup24Id}&wg_50_id=${this.ap.wlanGroup50Id}&rctl_id=${this.rctl_id}`)
                    .then((res) => {
                        this.hostedNetworks = false;
                        this.ap_members = res.data;
                        this.$toast.success("Successfully fetched member WLANs")
                    }).catch((error) => {
                    this.hostedNetworks = false;
                    this.$toast.error(error)
                })


            },
        },
        mounted() {
            this.validateSession()
            this.wlanQuery()
        }
    }
</script>


<style scoped>
    .lds-facebook {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-facebook div {
        display: inline-block;
        position: absolute;
        left: 8px;
        width: 16px;
        background: #4673e7;
        animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }

    .lds-facebook div:nth-child(1) {
        left: 8px;
        animation-delay: -0.24s;
    }

    .lds-facebook div:nth-child(2) {
        left: 32px;
        animation-delay: -0.12s;
    }

    .lds-facebook div:nth-child(3) {
        left: 56px;
        animation-delay: 0;
    }

    @keyframes lds-facebook {
        0% {
            top: 8px;
            height: 64px;
        }
        50%, 100% {
            top: 24px;
            height: 32px;
        }
    }

    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-ripple div {
        position: absolute;
        border: 4px solid #4673e7;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }

    @keyframes lds-ripple {
        0% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: 0px;
            left: 0px;
            width: 72px;
            height: 72px;
            opacity: 0;
        }
    }


</style>